<template>
    <div class="card card-body mt-4">
      <h3>Add New Vertical</h3>
      <form @submit.prevent="submit">
        <div class="form-group mt-3">
          <label>Title</label>
          <input
            v-model="form.title" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Description</label>
          <input v-model="form.description" class="form-control" required />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>
  
        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Insert
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getVertical, createVertical } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      
  
      const form = reactive({ description: '', title: '' })
        
      const submit = async () => {
        await createVertical({ ...form})
        router.push('/vertical')
        form.description = ''
        form.title = ''
      }

      const cancelBtn = () => {
        router.push('/vertical')
      }
  
      return { form, submit, cancelBtn}
    }
  }
  </script>