<template>
    <div class="card mt-4">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!loading"  v-for="{ id, title, description } in verticals" :key="id">
            <td><router-link :to="`/vertical/${id}/feed`">{{ title }}</router-link></td>
            <td>{{ description }}</td>
            <td>
              <router-link :to="`/vertical/edit/${id}`">
                <button class="btn btn-primary btn-sm me-2">
                  Edit
                </button>
              </router-link>
              <button class="btn btn-danger btn-sm" @click="deleteVerticalWrapper(id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
<script>
import { useLoadVerticals, deleteVertical } from '@/firebase'
export default {
  data() {
    return {
      loading: false,
      verticals: []
    }
  },
  created() {
    this.getVerticalList()
  },
  methods: {
    getVerticalList() {
      this.loading = true
      useLoadVerticals().then(response => {
        this.loading = false
        this.verticals = response
      })
      .catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    deleteVerticalWrapper(id){
        deleteVertical(id).then(()=>{
            this.getVerticalList()
        })
    }
  }
}
</script>