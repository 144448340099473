// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, onSnapshot, collection, getDocs, doc, addDoc, setDoc, where, updateDoc, getDoc, deleteDoc, query, and } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { md5 } from 'js-md5';

import {ref, onUnmounted} from 'vue';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD1KaL9bGJrCdOV6BHAR1Aqe-9dgcPciuM',
  authDomain: 'front-page-systems.firebaseapp.com',
  projectId: 'front-page-systems',
  storageBucket: 'front-page-systems.appspot.com',
  messagingSenderId: '854518363539',
  appId: '1:854518363539:web:7136da9e879ff85e47a466',
  measurementId: 'G-R7KJH0G3H9'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);


export const createAdminUser = async user => {

  return await addDoc(collection(db, "Admin-Users"), {
    name: user.name,
    email: user.email,
    password: md5(user.password)
  });
}

export const createVertical = async vertical => {
    
  return await addDoc(collection(db, "Verticals"), {
    description: vertical.description,
    title: vertical.title
  });
}


export const createFeedType = async feedType => {
    
  return await addDoc(collection(db, "Feed-Type"), {
    description: feedType.description,
    title: feedType.title,
    typeId: feedType.typeId,
    apiurl: feedType.apiurl,
    apikey: feedType.apikey,
    apipassword: feedType.apipassword
  });
}

export const createVerticalFeed = async (verticalId, verticalFeed) => {
    
  return await addDoc(collection(db, "/Verticals/"+verticalId+"/Vertical-Feeds/"), {
    title: verticalFeed.title,
    description: verticalFeed.description,
    authority: verticalFeed.authority,
    quality: verticalFeed.quality,
    type: verticalFeed.type,
    typeId: verticalFeed.typeId,
    url: verticalFeed.url,
    is_active: verticalFeed.is_active,
  });
}

export const getAdminUser = async id => {
    
    
    const docRef = doc(db, "Admin-Users", id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      
      let user = {
        id: docSnap.id,
        name: docSnap.get('name'),
        email: docSnap.get('email')
      }
      return user;
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
}

export const getFeedType = async id => {
    
    
  const docRef = doc(db, "Feed-Type", id);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    
    let vertical = {
      id: docSnap.id,
      description: docSnap.get('description'),
      title: docSnap.get('title'),
      typeId: docSnap.get('typeId'),
      apiurl: docSnap.get('apiurl'),
      apikey: docSnap.get('apikey'),
      apipassword: docSnap.get('apipassword')
    }
    return vertical;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

export const getVertical = async id => {
    
    
  const docRef = doc(db, "Verticals", id);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    
    let vertical = {
      id: docSnap.id,
      description: docSnap.get('description'),
      title: docSnap.get('title')
    }
    return vertical;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

export const getVerticalFeed = async (verticalId, id) => {
    
    
  const docRef = doc(db, "/Verticals/"+verticalId+"/Vertical-Feeds/", id);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    
    let verticalFeed = {
      id: docSnap.id,
      title: docSnap.get('title'),
      description: docSnap.get('description'),
      authority: docSnap.get('authority'),
      quality: docSnap.get('quality'),
      type: docSnap.get('type'),
      typeId: docSnap.get('typeId'),
      url: docSnap.get('url'),
      is_active: docSnap.get('is_active')
    }
    return verticalFeed;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

export const updateSelf = async (id, name, email, password) => {
  const docRef = doc(db, "Admin-Users", id);

  let user = {
      id: id,
      name: name,
      email: email
    }
  if (password != null && password != ''){
    user.password = md5(password)      
  }
  await updateDoc(docRef, user);
}

export const updateAdminUser = async (id, user) => {
  await updateObject(id, "Admin-Users", user)
}

export const updateVertical = async (id, vertical) => {
  await updateObject(id, "Verticals", vertical)
}

export const updateFeedType = async (id, feedType) => {
  await updateObject(id, "Feed-Type", feedType)
}

export const updateVerticalFeed = async (verticalId, verticalFeedId, verticalfeed) => {
    
  const docRef = doc(db, "/Verticals/"+verticalId+"/Vertical-Feeds/", verticalFeedId);
  
  await updateDoc(docRef, verticalfeed);
}

export const updateObject = async (id, tableName, object) => {
    
  const docRef = doc(db, tableName, id);
  
  await updateDoc(docRef, object);
}

export const deleteAdminUser = async id => {
  const docRef = doc(db, "Admin-Users", id);
  await deleteDoc(docRef);
}

export const deleteVertical = async id => {
  const docRef = doc(db, "Verticals", id);
  await deleteDoc(docRef);
}

export const deleteFeedType = async id => {
  const docRef = doc(db, "Feed-Type", id);
  await deleteDoc(docRef);
}

export const deleteVerticalFeed = async (verticalId, id) => {
  const docRef = doc(db, "/Verticals/"+verticalId+"/Vertical-Feeds/", id);
  await deleteDoc(docRef);
}

export const useLoadAdminUsers = async () => {
  
  const users = []

  const querySnapshot = await getDocs(collection(db, "Admin-Users"));
  
  querySnapshot.forEach((doc) => {
            
    let user = {
      id: doc.id,
      name: doc.get('name'),
      email: doc.get('email')
    }
    users.push(user)
  });

  return users
}

export const useLoadFeedTypes = async () => {
  
  const feedTypes = []

  const querySnapshot = await getDocs(collection(db, "Feed-Type"));
  
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        
    let feedType = {
      id: doc.id,
      description: doc.get('description'),
      title: doc.get('title'),
      apiurl: doc.get('apiurl'),
      apikey: doc.get('apikey'),
      apipassword: doc.get('apipassword'),
      typeId: doc.get('typeId')
    }
    feedTypes.push(feedType)
  });

  return feedTypes
}

export const useLoadVerticals = async () => {
  
  const verticals = []

  const querySnapshot = await getDocs(collection(db, "Verticals"));
  
  querySnapshot.forEach((doc) => {
  
    let vertical = {
      id: doc.id,
      description: doc.get('description'),
      title: doc.get('title')
    }
    verticals.push(vertical)
  });

  return verticals
}

export const useLoadVerticalFeeds = async id => {
  
  const verticalfeeds = []

  const querySnapshot = await getDocs(collection(db, "/Verticals/"+id+"/Vertical-Feeds"));
  
  querySnapshot.forEach((doc) => {
        
    let verticalfeed = {
      id: doc.id,
      verticalId: id,
      title: doc.get('title'),
      description: doc.get('description'),
      authority: doc.get('authority'),
      quality: doc.get('quality'),
      type: doc.get('type'),
      typeId: doc.get('typeId'),
      url: doc.get('url'),
      is_active: doc.get('is_active')

    }
    verticalfeeds.push(verticalfeed)
  });

  return verticalfeeds
}

export const useLoadVerticalFeedContent = async (verticalId, id) => {
  
  const verticalContents = []

  const querySnapshot = await getDocs(collection(db, "/Verticals/"+verticalId+"/Vertical-Content/"));
  
  querySnapshot.forEach((doc) => {
        
    let verticalContent = {
      id: doc.id,
      contentId: id,
      channelName: doc.get('channel_name'),
      channelImage: doc.get('channel_image'),
      title: doc.get('title'),
      description: doc.get('description'),
      feedId: doc.get('feed_id'),
      publishDate: doc.get('pub_date'),
      link: doc.get('link')
    }
    verticalContents.push(verticalContent)
  });

  return verticalContents
}

export const loginAdminUser = async (email, password) => {
  
  const q = query(collection(db, "Admin-Users"), and(where("email", "==", email), where("password", "==", md5(password))));

  const querySnapshot = await getDocs(q);
  
  let user = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    user = {
      id: doc.id,
      name: doc.get('name'),
      email: doc.get('email')
    }
    console.log("Found user for login")
    document.cookie = "FPS-Admin" + '=' + doc.id;
  });

  
  return user;
  console.log("returned")
  
}

export const getAdminFromSession = async () => {

  let keyValue = document.cookie.match('(^|;) ?' + "FPS-Admin" + '=([^;]*)(;|$)');

  if (keyValue != null){
    let userId = keyValue[2]
    let user = getAdminUser(userId)

    return user
  }

  console.log("user id: "+user.value)
}